import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

function FloatingComponent({ children, speedX, speedY, ...props }) {
  const [offset, setOffset] = useState(0);

  const handleScroll = useCallback(() => {
    const currentOffset = window.pageYOffset;
    setOffset(currentOffset);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const styles = {
    transform: `translate(${offset * speedX}px, ${offset * speedY}px)`, // adjust the factor to control the floating effect
    transition: 'transform 0.2s ease-out',
    zIndex: 999, // ensure that the component is on top of other elements
    /* other styles here */
    ...props,
  };

  return (
    <Box sx={styles}>
      {children}
    </Box>
  );
}

FloatingComponent.propTypes = {
  children: PropTypes.node.isRequired,
  speedX: PropTypes.number,
  speedY: PropTypes.number,
};

FloatingComponent.defaultProps = {
  speedX: 0,
  speedY: 0.05,
};

export default FloatingComponent;
