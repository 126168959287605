import React, { useCallback, useMemo } from 'react';
import { Typography, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { PDFIcon, XMLIcon } from '../../assets';
import { FILE_URL } from '../../graphql';
import { openUrlAndDownload } from '../../helpers';
import { useSnackBars, useGetCountryFromUrl } from '../../hooks';
import LoadingIconButton from './LoadingIconButton';
import { InvoiceDocumentPropType } from '../../propTypes/InvoiceDocumentType';
import { disabledDocumentsDownload,
  disabledDownloadDocumentsMessage,
  errorDownloadDocumentsMessage } from '../../helpers/invoice-document-download-conditions';

const DownloadInvoiceDocumentButton = ({ invoice, showTitle, documentType, ...props }) => {
  const { addAlert } = useSnackBars();
  const country = useGetCountryFromUrl();
  const documentDisabledTitle = useMemo(() => disabledDownloadDocumentsMessage(
    country?.name,
  ), [country]);
  const errorMessage = useMemo(() => errorDownloadDocumentsMessage(country?.name), [country]);
  const documentDisabled = useMemo(
    () => disabledDocumentsDownload(invoice, country?.name),
    [country],
  );

  const findDocument = useCallback(
    (_invoice, _documentType) => _invoice.documents.find(
      ({ type: { code } }) => code === _documentType,
    ),
    [],
  );
  const documentInstance = useMemo(
    () => findDocument(invoice, documentType),
    [invoice, documentType],
  );
  const [_getInvoiceFile, { loading: loadingDocument }] = useMutation(FILE_URL, {
    variables: {
      invoiceId: invoice.id,
    },
    onCompleted: (_data) => {
      const { invoice: fetchedInvoice } = _data.invoicePdfUrl;
      const document = findDocument(fetchedInvoice, documentType);
      if (document) {
        openUrlAndDownload(document.file.url, documentType);
      } else {
        addAlert({
          id: 'document-not-available',
          message: 'Documento no disponible 😕',
        });
      }
    },
    onError: () => {
      addAlert({
        id: 'invoice-document-error',
        message: errorMessage,
      });
    },
  });
  const getInvoiceFile = useCallback(() => {
    if (documentInstance) {
      return openUrlAndDownload(documentInstance.file.url, documentType);
    }
    return _getInvoiceFile();
  }, [documentInstance]);

  return (
    <Tooltip title={documentDisabled && documentDisabledTitle}>
      <span>
        <LoadingIconButton
          color="gray"
          onClick={getInvoiceFile}
          disabled={documentDisabled}
          loading={loadingDocument}
          {...props}
        >
          <img
            src={documentType === 'PDF' ? PDFIcon : XMLIcon}
            alt="document-icon"
            style={{
              height: '24px',
              ...(documentDisabled && { filter: 'opacity(0.3)' }),
            }}
          />
          {showTitle && (
          <Typography
            variant="h2"
            sx={{ ml: '5px', display: { xs: 'none', sm: 'inline', md: 'inline', lg: 'inline' } }}
          >
            Descargar {documentType.toUpperCase()}
          </Typography>
          )}
        </LoadingIconButton>
      </span>
    </Tooltip>
  );
};

DownloadInvoiceDocumentButton.propTypes = {
  documentType: PropTypes.oneOf(['PDF', 'XML']).isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.shape({
      hasInvoiceProviderCredentials: PropTypes.bool,
    }),
    receiver: PropTypes.shape({
      company: PropTypes.shape({
        hasInvoiceProviderCredentials: PropTypes.bool,
      }),
    }),
    documents: PropTypes.arrayOf(InvoiceDocumentPropType).isRequired,
  }).isRequired,
  showTitle: PropTypes.bool,
};
DownloadInvoiceDocumentButton.defaultProps = {
  showTitle: false,
};

export default DownloadInvoiceDocumentButton;
