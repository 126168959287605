import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Typography } from '@mui/material';

const AvatarWithCaption = ({ value, label, bgcolor }) => (
  <Grid container alignItems="center">
    <Avatar
      sx={{
        width: 26,
        height: 26,
        bgcolor,
        mx: 1,
      }}
    >
      <Typography variant="subtitle2">{value}</Typography>
    </Avatar>
    <Typography display="inline">{label}</Typography>
  </Grid>
);

AvatarWithCaption.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
};

AvatarWithCaption.defaultProps = {
  value: null,
  bgcolor: null,
};

export default AvatarWithCaption;
