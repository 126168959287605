import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SelectableButtonGroup = ({ buttonInfo, buttonProps, ...props }) => {
  const [selectedButton, setSelectedButton] = useState(buttonInfo[0].id);
  const { sx: buttonSx, ...restButtonProps } = buttonProps;
  return (
    <Stack bgcolor="gray.light" direction="row" borderRadius={2} {...props}>
      {buttonInfo.map(({ id, label, callback }) => (
        <Button
          fullWidth
          disableRipple
          color="text"
          sx={{ '&:hover': { backgroundColor: selectedButton === id ? 'white' : 'transparent' },
            m: 1,
            background: selectedButton === id ? 'white' : 'transparent',
            boxShadow: selectedButton === id ? 1 : 0,
            ...buttonSx,
          }}
          onClick={() => { setSelectedButton(id); callback(id); }}
          {...restButtonProps}
        >
          {label}
        </Button>
      ))}
    </Stack>
  );
};

SelectableButtonGroup.propTypes = {
  buttonInfo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    callback: PropTypes.func,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: PropTypes.object,
};

SelectableButtonGroup.defaultProps = {
  buttonProps: {},
};

export default SelectableButtonGroup;
