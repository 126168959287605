import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InvoiceAttachableFilesArray from './InvoiceAttachableFilesArray';

const InvoicesAttachableFiles = ({ documents, managersAndAttachs, setManagersAndAttachs }) => (
  <Grid container direction="column">
    {documents.map((doc) => (
      <InvoiceAttachableFilesArray
        document={doc}
        managersAndAttachs={managersAndAttachs}
        setManagersAndAttachs={setManagersAndAttachs}
      />
    ))}
  </Grid>
);

InvoicesAttachableFiles.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  documents: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  managersAndAttachs: PropTypes.array.isRequired,
  setManagersAndAttachs: PropTypes.func.isRequired,
};

export default InvoicesAttachableFiles;
