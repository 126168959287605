import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Grid, Typography } from '@mui/material';
import Download from '@mui/icons-material/Download';
import { documentToAttachableList } from '../../helpers';

const InvoiceAttachableFilesArray = ({ document, managersAndAttachs, setManagersAndAttachs }) => {
  const attachableList = documentToAttachableList(document);
  const isChipActive = (documentType) => (
    managersAndAttachs.find((obj) => obj.managerId === document.collectionManager.id)[documentType]
  );
  const handleChipClick = (value) => {
    setManagersAndAttachs((prev) => {
      const newData = [...prev];
      const objToUpdate = newData.find((obj) => obj.managerId
       === String(document.collectionManager.id));
      objToUpdate[value] = !objToUpdate[value];
      return newData;
    });
  };
  return (
    <Grid item my={1}>
      <Typography variant="body2" sx={{ marginBottom: 1 }}>Folio {document.folio}</Typography>
      {attachableList.map((attachableFile) => (
        <Chip
          key={attachableFile.id}
          size="small"
          label={attachableFile.label}
          id={attachableFile.id}
          color={isChipActive(attachableFile.value) ? 'primary' : 'default'}
          variant={isChipActive(attachableFile.value) ? 'filled' : 'outlined'}
          onClick={() => handleChipClick(attachableFile.value)}
          onDelete={() => window.open(attachableFile.url)}
          deleteIcon={<Download />}
          sx={{ marginInline: 1, marginTop: 0.5 }}
        />
      ))}
    </Grid>
  );
};

InvoiceAttachableFilesArray.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  managersAndAttachs: PropTypes.array.isRequired,
  setManagersAndAttachs: PropTypes.func.isRequired,
};

export default InvoiceAttachableFilesArray;
