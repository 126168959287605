import React from 'react';
import { Button } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/styles';

const FilterButton = styled((props) => (
  <Button
    variant="text"
    color="text"
    endIcon={<ExpandMore />}
    size="small"
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.fingoWhite.main,
  color: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.fingoWhite.main,
  },
  [theme.breakpoints.down('md')]: {
    flex: '1 1 0px',
  },
}));

export default FilterButton;
